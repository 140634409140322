/**=====================
    74. Responsive CSS Start
==========================**/

/* ========= min and max scss for 1200 to 1366 screen ========= */
@media screen and (max-width: 1660px) and (min-width: 1367px) {
  .task-list {
    li {
      font-size: 15px;
    }
    margin: 10px;
  }
  .weather-widget {
    .weather-widget-top {
      height: 230px;
      .location {
        top: 50px;
        right: 30px;
      }
    }
    .weather-widget-bottom {
      .col {
        .box {
          &:before {
            left: 30px;
          }
          svg {
            width: 80px;
            height: 50px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1660px) {
  .add-arrow {
    .more-btn {
      .btn-secondary {
        display: none;
      }
      .btn-light {
        width: 100%;
        border: 1px solid #f4eefd !important;
        border-radius: 15px;
        padding: 27px 21px;
        margin-right: 0;
      }
    }
  }
  .weather-widget {
    .weather-widget-bottom {
      .col {
        .box {
          &:before {
            left: 20px;
          }
        }
      }
    }
  }
  .browser-widget {
    img {
      height: 60px;
    }
  }
  .new-products {
    .product-box {
      .product-details {
        padding: 10px;
      }
    }
  }

  .photos {
    ul {
      li {
        width: 26%;
      }
    }
  }
  .user-status {
    table {
      thead {
        tr {
          th {
            &:nth-child(3) {
              min-width: 124px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:first-child {
              min-width: 320px;
            }
          }
        }
      }
    }
  }
  .bar-chart {
    canvas {
      width: 100% !important;
    }
  }
  .chat-box {
    .chat-history {
      .call-icons {
        ul {
          li {
            border: 1px solid $dark-gray;
          }
        }
      }
      .total-time {
        h2 {
          color: $dark-gray;
        }
      }
      .call-content {
        .btn {
          width: auto;
          margin: 0 auto;
        }
      }
    }
  }
  .chat-left-aside {
    .people-list {
      height: 520px;
    }
  }
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-msg-box {
          height: 440px;
        }
      }
    }
    .people-list {
      ul {
        max-height: 450px;
        overflow: auto;
      }
    }
  }
  .call-chat-body {
    .chat-box {
      .people-list {
        ul {
          max-height: 550px;
          overflow: auto;
        }
      }
      overflow: hidden;
      height: 625px;
    }
  }
  .caller-img {
    position: absolute;
    width: 100%;
    max-width: 100%;
    left: 15px;
    img {
      opacity: 0.7;
    }
  }
  .chat-box {
    .chat-history {
      .receiver-img {
        margin-top: 25px;
      }
      .call-content {
        > div {
          height: 545px;
          z-index: 9;
          background-color: rgba(255, 255, 255, 0.75);
          background-blend-mode: overlay;
          width: 100%;
          padding: 30px;
        }
      }
    }
  }
}
@media (max-width: 1280px) {
  .box-layout.page-wrapper {
    .page-main-header {
      padding-left: unset;
      max-width: unset;
      left: unset;
    }
    .page-body-wrapper {
      width: unset;
      .page-sidebar {
        &.open {
          ~ footer {
            width: unset;
          }
        }
      }
      .footer {
        margin-left: unset;
        width: unset;
      }
    }
  }
}
@media screen and (max-width: 1580px) and (min-width: 1200px) {
  .docs-buttons,
  .docs-toggles {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1440px) {
  // timeline small
  .iconsidebar-menu  {
    &.iconbar-mainmenu-close, &.iconbar-second-close {
      ~ .page-body {
        .timeline-small {
          .media {
            .timeline-round {
              &.timeline-line-1 {
                &:after {
                  bottom: -67px;
                }
              }
            }
          }
        }
      }
    }
  }
  // product page
  .product-filter {
    &.new-products {
      .product-box {
        > div {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .product-img {
          img {
            width: 100px;
            margin: 0 auto;
          }
        }
        .product-details {
          padding: 15px;
          text-align: center !important;
        }
      }
    }
  }
  .product-wrapper {
    &.sidebaron {
      .product-grid {
        .product-wrapper-grid {
          min-height: 1280px;
        }
      }
    }
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1366px) {
  .flot-chart-container-small {
    height: 227px;
  }
  .call-chat-sidebar {
    max-width: 260px;
  }
  // product page
  .product-box {
    .product-details {
      padding: 15px;
      p {
        margin-bottom: 10px;
      }
    }
  }
  .flot-chart-container {
    height: 282px;
  }
  .custom-card {
    .card-profile {
      img {
        height: 115px;
      }
    }
    .profile-details {
      h4 {
        font-size: 18px;
      }
      h6 {
        margin-bottom: 24px;
      }
    }
    .card-footer {
      > div {
        h3 {
          font-size: 18px;
        }
      }
    }
  }
  .xl-none {
    display: none;
  }
  .xl-60 {
    max-width: 60%;
    flex: 0 0 60%;
  }
  .xl-50 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .xl-100 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .xl-23 {
    max-width: 23%;
    flex: 0 0 23%;
  }
  .xl-25 {
    max-width: 25%;
    flex: 0 0 25%;
  }
  .xl-40 {
    max-width: 40%;
    flex: 0 0 40%;
  }
  .xl-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }
  .crm-activity {
    .media {
      .media-body {
        .dates {
          display: flex;
        }
      }
    }
  }
  // chat
  .chat-body {
    padding: 10px;
  }
  .chat-box {
    .chat-history {
      .call-content {
        .btn {
          font-size: 15px;
        }
      }
      .total-time h2 {
        font-size: 30px;
      }
      .receiver-img {
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .call-icons {
        margin-top: 20px;
        margin-bottom: 20px;
        ul {
          li {
            width: 50px;
            height: 50px;
            padding: 7px;
          }
        }
      }
    }
    .user-image {
      width: 30px;
      height: 30px;
      margin-top: 3px;
      margin-right: 3px;
    }
    .about {
      margin-top: 0;
    }
    .nav-material.nav-tabs {
      .nav-item {
        a {
          padding: 13px 4px !important;
          color: $semi-dark !important;
          letter-spacing: 0;
          font-size: 12px !important;
          line-height: 6;
        }
      }
    }

    .chat-menu {
      .people-list {
        ul.list {
          max-height: 376px;
          overflow-y: auto;
        }
      }
      .user-profile {
        .image {
          .avatar {
            img {
              width: 100px;
              height: 100px;
            }
          }
          .icon-wrapper {
            width: 30px;
            height: 30px;
            font-size: 13px;
          }
        }
      }
    }
  }

  .chat-left-aside {
    .people-list {
      height: 420px;
      ul {
        max-height: 376px;
        overflow-y: auto;
      }
    }
    .status-circle {
      top: 4px;
      left: 36px;
    }
  }
  //blog scss
  .blog-box {
    .blog-details-main {
      .blog-bottom-details {
        margin: 20px 10px;
      }
      .blog-social {
        li {
          padding: 0px 10px;
        }
      }
    }
  }
  .blog-bottom-content {
    display: none;
  }
  .blog-box.blog-shadow {
    .blog-details {
      padding: 10px;
    }
  }
  .browser-widget {
    img {
      height: 50px;
    }
  }

  // email-app
  .email-wrap {
    .email-left-aside {
      .email-app-sidebar {
        .media {
          display: block;
          .media-size-email {
            width: 100%;
            text-align: center;
            .mr-3 {
              margin-right: 0 !important;
            }
          }
          .media-body {
            text-align: center;
          }
        }
      }
    }
    .email-right-aside {
      .email-body {
        .attachment {
          ul {
            li {
              img {
                width: 73px;
                height: 73px;
              }
            }
          }
        }
        .email-top {
          line-height: 50px;
          .float-right {
            display: none !important;
          }
          h5 {
            padding: 13px 0;
          }
        }
        .inbox {
          max-height: 668px;
          overflow: auto;
          .media {
            display: block;
            .media-size-email {
              width: 100%;
              text-align: center;
              .mr-3 {
                margin-right: 0 !important;
              }
            }
          }
          .media-body {
            text-align: center;
            h6 {
              font-size: 14px;
            }
          }
        }
        .email-compose {
          .cke_contents.cke_reset {
            max-height: 144px;
            width: 99%;
          }
          .compose-border {
            margin: 0;
          }
        }
      }
    }
  }

  // alert page
  .alert-dismissible {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 181px;
    }
  }
  // touchspin page
  .bootstrap-touchspin {
    .touchspin-vertical-tab {
      .input-group {
        width: 96% !important;
      }
    }
  }
}
/* ========= min and max scss for 992 to 768 screen ========= */
@media only screen and (max-width: 1440px) {
  .xl-mt-job {
    margin-bottom: 20px;
  }
  .lg-mt {
    margin-top: 30px;
  }
  // timeline
  .timeline-content {
    .date {
      padding: 8px;
    }
  }
  .timeline-item {
    &:nth-child(odd) {
      .timeline-content {
        .inner-content {
          margin-right: 0 !important;
        }
      }
    }
  }
}

/* ========= responsive scss for 1366 screen ========= */
@media only screen and (max-width: 1366px) {
  app-calender {
    .card-body {
      > .row {
        display: block;
        > div {
          flex: 0 0 100%;
          max-width: 100%;
          h3 {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .contact-table {
    table {
      tr {
        td {
          &:last-child {
            min-width: 270px;
          }
        }
      }
    }
  }
  .support-ticket-font {
    h3 {
      font-size: 22px;
    }
  }
  .email-wrap {
    .email-right-aside {
      .email-body {
        .inbox {
          .media {
            label {
              text-align: center;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
  // main header css
  .page-main-header {
    .main-header-right {
      .nav-right {
        > ul {
          > li {
            .badge {
              display: none;
            }
          }
        }
      }
    }
  }
  // form wizard
  aw-wizard-navigation-bar {
    &.vertical {
      width: 30% !important;
    }
  }
  // timeline small page
  .timeline-small {
    .media {
      .media-body {
        p {
          width: 100%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
        }
      }
      .small-line {
        ~ .media-body {
          p {
            -webkit-line-clamp: 1;
          }
        }
      }
    }
  }
  .map-js-height {
    height: 350px;
  }
  // chat page
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-msg-box {
          height: 364px;
          .chat-user-img {
            margin-top: -30px;
          }
          .message {
            width: 100%;
            padding: 15px;
          }
        }
      }
    }
  }
  .chat-right-aside {
    flex: 0 0 60%;
    max-width: 60%;
    overflow: hidden;
    .chat {
      .chat-message {
        .smiley-box {
          width: 45px;
        }
      }
    }
  }
  .chat-box {
    .chat-history {
      .receiver-img {
        img {
          height: 150px;
        }
      }
    }
  }
  .call-chat-body {
    .chat-box {
      overflow: hidden;
      height: 565px;
    }
  }
  .equal-height-xl {
    height: 517px;
  }

  .authentication-main {
    .auth-innerright {
      background-size: cover;
    }
  }
  .grp-btns {
    display: inline-block;
  }
  //ecommerce general
  .ecommerce-widget {
    .total-num {
      font-size: 23px;
      letter-spacing: 0px;
    }
  }
  .support-ticket-font {
    .total-num {
      font-size: 22px;
    }
  }
  //default general
  .custom-card {
    .card-social {
      li {
        a {
          padding: 12px;
        }
      }
    }
  }
  .map-chart {
    .chart-container {
      height: 300px;
    }
  }
  .speed-chart {
    .chart-container {
      height: 250px;
      margin-top: -40px;
      margin-bottom: 100px;
    }
  }
  .candidcahrt {
    height: 360px;
  }

  //chart widget
  .status-details {
    h3 {
      font-size: 18px;
    }
  }
  .chart-widget-top {
    .total-value {
      font-size: 20px;
    }
  }
  .status-widget {
    .card-body {
      padding: 10px;
    }
  }
  //general widget
  .user-status {
    table {
      thead {
        tr {
          th {
            &:nth-child(3) {
              min-width: unset;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:first-child {
              min-width: unset;
            }
          }
        }
      }
    }
  }
  .browser-widget {
    .media {
      flex-wrap: wrap;
      .media-body {
        h3 {
          font-size: 20px;
        }
        p {
          margin-bottom: 2px;
        }
        h4 {
          font-size: 20px;
        }
      }
    }
  }
  .testimonial {
    i {
      font-size: 46px;
    }
    p {
      margin-top: 20px;
    }
  }
  .cal-date-widget .datepicker {
    margin: 0 auto;
    padding: 0;
    width: unset;
  }
  .static-top-widget {
    .media-body {
      h3 {
        font-size: 30px;
      }
      .icon-bg {
        right: -21px;
      }
    }
  }
  .widget-joins {
    .media {
      padding: 20px;
      .media-body {
        h3 {
          font-size: 20px;
        }
      }
    }
  }
  .redial-social-widget {
    width: 125px;
    height: 125px;
    i {
      width: 100px;
      height: 100px;
      font-size: 35px;
    }
  }

  //chat
  .chat-body {
    padding: 10px;
  }
  .chat-box {
    .chat-history {
      .call-content {
        .btn {
          font-size: 15px;
        }
      }
      .total-time h2 {
        font-size: 30px;
      }
      .receiver-img {
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .call-icons {
        margin-top: 20px;
        margin-bottom: 20px;
        ul {
          li {
            width: 50px;
            height: 50px;
            padding: 7px;
          }
        }
      }
    }
    .user-image {
      width: 45px;
      height: 45px;
    }
    .about {
      margin-top: 0;
    }
    .nav-material.nav-tabs {
      .nav-item {
        a {
          padding: 13px 4px !important;
          color: $semi-dark !important;
          letter-spacing: 0;
          font-size: 12px !important;
          line-height: 6;
        }
      }
    }
    .chat-menu {
      .people-list {
        ul.list {
          max-height: 376px;
          overflow-y: auto;
        }
      }
      .user-profile {
        margin-top: 0;
        .image {
          .avatar {
            img {
              width: 100px;
              height: 100px;
            }
          }
          .icon-wrapper {
            width: 30px;
            height: 30px;
            font-size: 13px;
          }
        }
        .user-content {
          p {
            font-size: 14px;
            margin-bottom: 10px;
          }
          h5 {
            margin: 20px 0;
          }
          hr {
            margin: 20px 0;
          }
        }
        .follow {
          .follow-num {
            font-size: 20px;
          }
        }
      }
    }
  }

  .chat-left-aside {
    .people-list {
      height: 490px;
      ul {
        max-height: 434px;
        overflow-y: auto;
      }
    }
  }
  .status-circle {
    top: 4px;
    left: 37px;
  }
  // image cropper page
  .img-cropper {
    .docs-toggles {
      margin-top: 8px;
    }
  }
  // touchspin page
  .bootstrap-touchspin {
    .dropdown-basic {
      .dropdown {
        .dropdown-content {
          left: -21px;
        }
      }
    }
  }
  // datatable advance page
  #advance-5_wrapper {
    .dataTables_paginate {
      margin-bottom: 8px;
    }
  }
  .product-table {
    #basic-1_wrapper {
      .dataTable {
        tbody {
          tr {
            td {
              &:last-child {
                min-width: 160px;
              }
            }
          }
        }
      }
    }
  }
  .custom-datatable {
      &.full-screen-datatable {
        .ngx-datatable {
          &.bootstrap {
            .datatable-body {
              .datatable-body-row {
                .datatable-body-cell {
                  &:first-child {
                    width: 50px !important;
                  }
                  &:nth-child(3), &:nth-child(4) {
                    width: 80px !important;
                  }
                }
              }
            }
          }
          &.fixed-header {
            .datatable-header {
              .datatable-header-inner {
                .datatable-header-cell {
                  &:first-child {
                    width: 50px !important;
                  }
                  &:nth-child(3), &:nth-child(4) {
                    width: 80px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  // forget password page
  .reset-password-box {
    .theme-form {
      .form-group {
        .btn {
          padding: 6px 22px;
        }
      }
    }
  }
  // map
  agm-map {
    height: 350px !important;
  }
  .leaflet-custom {
    .map-height {
      height: 350px;
    }
  }
}

/* ========= responsive scss for 1200 screen ========= */
@media only screen and (max-width: 1199px) {
  .contact-filter {
    margin-top: 15px;
  }
  .monthly-overview {
    .rounds-decore {
      display: none;
    }
  }
  .pricing-simple {
    .card-body {
      h1 {
        font-size: 70px;
      }
    }
  }
  #cd-timeline {
    &::before {
      display: none;
    }
  }

  .alert-theme {
    max-width: 400px;
  }
  // support ticket page
  .ecommerce-widget {
    .support-ticket-font {
      padding: 20px;
    }
  }
  // product page
  .product-sidebar {
    &.open {
      top: -45px;
    }
  }
  .product-wrapper {
    &.sidebaron {
      .product-grid {
        .product-wrapper-grid {
          min-height: 920px;
          margin-left: 0;
        }
      }
      .product-sidebar {
        .filter-section {
          .card {
            .left-filter {
              top: 0;
            }
          }
        }
      }
    }
  }
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        .product-img {
          width: 42%;
        }
      }
    }
  }
  .product-filter {
    .product-box {
      flex-wrap: nowrap;
      .product-img {
        width: 100px;
      }
      .product-details {
        padding: 0 !important;
      }
    }
  }
  .feature-products {
    .filter-toggle {
      padding: 8px 15px;
      background-color: $white;
      margin-left: 10px;
      border-radius: 5px;
    }
    span {
      &.f-w-600 {
        padding-top: 8px;
        display: inline-block;
      }
    }
  }
  .d-none-productlist {
    display: block;
    margin-right: 10px;
  }
  .product-sidebar {
    transform: translateX(-300px) scaleX(0);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    height: 0;
    position: absolute;
    z-index: 7;
    width: 300px;
    &.open {
      transform: translateX(0px) scaleX(1);
      box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
      visibility: visible;
      opacity: 1;
      height: auto;
      top: 0;
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 7px solid $transparent-color;
        border-right: 7px solid $transparent-color;
        border-bottom: 7px solid $white;
        right: 120px;
        top: -7px;
      }
    }
    .filter-section {
      .card-header {
        display: none;
      }
    }
  }
  body.dark-only {
    .product-sidebar {
      &.open {
        &:before &:after {
          border-bottom: 7px solid $dark-card-background;
        }
      }
    }
  }
  // main header css
  .page-wrapper {
    .page-main-header {
      .vertical-mobile-sidebar {
        display: none;
      }
      .main-header-right {
        .nav-right {
          position: unset;
          > .mobile-toggle-left {
            cursor: pointer;
            display: flex;
            align-items: center;
            svg {
              circle {
                color: $primary-color;
              }
            }
          }
          > ul {
            li {
              &:first-child {
                padding-left: 0 !important;
                &:before {
                  display: none;
                }
              }
              .submenu {
                li {
                  &:first-child {
                    padding-left: 15px !important;
                  }
                }
              }
            }
          }
          .category-dropdown,
          .status-dropdown {
            top: 38px;
          }
        }
        .mobile-sidebar {
          .switch-sm {
            .switch {
              margin-top: 6px;
            }
          }
        }
      }
    }
    .page-body-wrapper {
      .iconsidebar-menu {
        .iconMenu-bar {
          .iconbar-mainmenu {
            .iconbar-header {
              padding: 21px 0 21px 20px;
            }
          }
        }
        &.iconbar-second-close {
          ~ .page-body,
          ~ footer {
            margin-left: 0 !important;
          }
        }
      }
    }
  }
  // datatable
  .custom-datatable {
    input {
      width: 50%;
    }
  }
  .custom-datatable {
    &.full-screen-datatable {
      .ngx-datatable {
        &.bootstrap {
          .datatable-body {
            .datatable-body-row {
              .datatable-body-cell {
                &:nth-child(2), &:nth-child(5), &:nth-child(6) {
                  width: 150px !important;
                }
              }
            }
          }
        }
        &.fixed-header {
          .datatable-header {
            .datatable-header-inner {
              .datatable-header-cell {
                &:nth-child(2), &:nth-child(5), &:nth-child(6) {
                  width: 150px !important;
                }
              }
            }
          }
        }
      }
    }
  }
  // form wizard
  aw-wizard-navigation-bar {
    &.vertical {
      width: 40% !important;
    }
  }
  // pagination
  .pagination-lg {
    .page-link {
      padding: 10px 18px;
    }
  }
  // social app page
  .photos {
    ul {
      li {
        width: auto;
        &:nth-child(3n) {
          margin-right: 15px;
        }
      }
    }
  }
  .user-profile {
    .hovercard {
      .user-image {
        .share-icons {
          position: absolute;
          right: 45px;
          top: -22px;
          li {
            .social-icon {
              width: 40px;
              height: 40px;
              i {
                font-size: 18px;
              }
            }
            color: #fff;
            font-size: 25px;
          }
        }
      }
    }
  }
  // timeline small page
  .timeline-small {
    .media {
      .timeline-round {
        &.timeline-line-1 {
          &:after {
            height: 50px;
            bottom: -60px;
          }
        }
        &.small-line {
          &:after {
            height: 28px;
            bottom: -38px;
          }
        }
        &.medium-line {
          &:after {
            height: 48px;
            bottom: -60px;
          }
        }
      }
      .media-body {
        h6 {
          font-weight: 600;
          margin-bottom: 30px;
          span {
            font-weight: normal;
            color: $light-text;
          }
        }
        p {
          width: 80%;
        }
      }
    }
  }
  .iconsidebar-menu  {
    &.iconbar-second-close {
      ~ .page-body {
        .timeline-small {
          .media {
            .timeline-round {
              &.timeline-line-1 {
                &:after {
                  height: 30px;
                  bottom: -40px;
                }
              }
            }
          }
        }
      }
    }
  }
  .lg-mt-col {
    margin-top: 30px;
  }
  .xl-none {
    display: none;
  }
  .user-status {
    table {
      tbody {
        tr {
          td {
            .d-inline-block {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .lg-mt {
    margin-top: 30px;
  }
  .product-page-details {
    margin-top: 30px;
  }
  .debit-card {
    order: 1;
  }
  .typography {
    .row {
      div {
        & + div {
          margin-top: 30px;
        }
      }
    }
  }
  .listing {
    .card-body {
      .row {
        > div {
          & + div {
            margin-top: 30px;
          }
        }
      }
    }
  }
  .flot-chart-container {
    height: 250px;
  }
  .img-cropper {
    #putData {
      margin-bottom: 10px;
    }
    .docs-toggles {
      > .dropdown {
        margin-bottom: 0;
      }
    }
  }
  .error-wrapper {
    .maintenance-icons {
      li {
        i {
          color: $light-color;
          font-size: 40px;
        }
        &:nth-child(2) {
          i {
            font-size: 80px;
            margin-left: 90px;
          }
        }
        &:nth-child(3) {
          i {
            font-size: 120px;
            margin-top: -75px;
            margin-right: 110px;
          }
        }
      }
    }
  }
  .jvector-map-height {
    height: 280px;
  }
  .blog-list {
    .blog-details {
      p {
        margin-bottom: 5px;
      }
    }
  }
  .custom-card {
    .card-header {
      img {
        margin-top: -25px;
        border-radius: 0;
      }
    }
    .card-profile {
      img {
        height: 115px;
      }
      .card-footer {
        > div {
          h3 {
            font-size: 18px;
          }
        }
      }
    }
  }
  .equal-height-lg {
    min-height: unset !important;
  }
  //blog
  .blog-single {
    .comment-box {
      ul {
        .comment-social {
          margin-left: 0;
        }
      }
    }
  }
  //error and maintenace
  .error-wrapper {
    .maintenance-heading {
      margin-top: 30px;
      .headline {
        font-size: 50px;
        margin-top: -15%;
        margin-bottom: 0px;
      }
      .cloud-second {
        margin-top: -25px;
      }
    }
  }
  // general widget
  .user-status {
    table {
      tbody {
        tr {
          td {
            &:first-child {
              min-width: 305px;
              img {
                width: 30px;
              }
            }
          }
        }
      }
    }
  }
  .testimonial {
    p {
      margin-top: 17px;
    }
  }
  .contact-form {
    padding: 10px;
    .theme-form {
      .form-icon {
        margin-top: -57px;
      }
    }
  }
  .browser-widget {
    padding: 0px;
    .media {
      .media-body {
        h4 {
          font-size: 17px;
        }
      }
      .media-img {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }
  //ecommerce general
  .crm-overall {
    .overall-chart {
      height: 200px;
    }
  }
  //chat
  .chat-box {
    .chat-menu {
      .people-list {
        ul.list {
          max-height: 384px;
          overflow-y: auto;
        }
      }
    }
    .btn-lg {
      font-size: 12px;
    }
    .people-list {
      ul {
        height: auto;
      }
    }
    .chat-right-aside {
      .chat {
        .chat-header {
          height: auto;
          .chat-menu-icons li a i {
            font-size: 19px;
          }
        }
        .chat-message {
          margin: 0;
        }
      }
    }
    .chat-history {
      .total-time h2 {
        font-size: 25px;
      }
      .row {
        .col-sm-7 {
          padding-right: 0;
        }
      }
    }
    .nav-material.nav-tabs {
      width: 100%;
      .nav-item {
        margin-right: 5px;
      }
    }
    .chat-left-aside {
      .people-list {
        height: 493px;
      }
    }
  }
  .chat-menu-icons {
    display: block;
    clear: both;
    width: 100%;
    text-align: right;
  }
  .chat-menu {
    right: 0;
    border-top: 1px solid $light-semi-gray;
    opacity: 0;
    transform: translateY(-30px);
    visibility: hidden;
    top: 120px;
    position: absolute;
    z-index: 9;
    background-color: $white;
    transition: all linear 0.3s;
    &.show {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
      transition: all linear 0.3s;
      padding-bottom: 25px;
    }
  }
  .chat-history {
    height: 447px;
  }
  .chat-box {
    .chat-history {
      .call-content {
        > div {
          padding-top: 70px;
        }
      }
    }
    .toogle-bar {
      display: inline-block;
      margin-right: 0 !important;
    }
  }
  .chat-right-aside {
    flex: 0 0 100%;
    max-width: calc(100% - 15px);
    overflow: hidden;
  }
  .call-chat-body {
    .chat-box {
      overflow: auto;
      height: auto;
    }
  }
  // Email app
  .email-wrap {
    .email-right-aside {
      .email-body {
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        .b-r-light {
          border-right: none !important;
        }
      }
      .radius-left {
        border-top-left-radius: 40px !important;
        border-bottom-left-radius: 40px !important;
      }
    }
    .row {
      .col-xl-3 {
        & + .col-xl-3 {
          padding-right: 15px;
        }
      }
      .col-xl-6 {
        padding-left: 15px;
      }
    }
  }
  // icons
  .icon-lists {
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 209px;
    }
  }
  // tour page
  .like-comment-lg-mb {
    margin-bottom: 12px;
  }
  // ribbons page
  .ribbon-space-bottom {
    top: 9px;
  }
  // touchspin page
  .bootstrap-touchspin {
    .touchspin-vertical-tab {
      .input-group {
        width: 98.5% !important;
      }
    }
  }
  // user-profile page
  .profile-img-style {
    #aniimated-thumbnials-3 {
      text-align: center;
    }
  }
}

/* ========= responsive scss for 992 screen ========= */
@media only screen and (max-width: 991px) {
  #document-toggle {
    margin-top: 20px;
  }
  .bottom-color-space {
    margin-bottom: -20px;
  }

  .tabbed-card{
    @each $border-tab-name, $border-tab-color in (primary, $primary-color ),
          (secondary, $secondary-color) ,
          (success, $success-color),
          (danger, $danger-color),
          (info, $info-color),
          (light, $light-color),
          (dark, $dark-color),
          (warning, $warning-color) {
    .borderb-tab-#{$border-tab-name}{
      &.nav-tabs , .nav-tabs {
        top: -13px;
        .nav-item {
          .nav-link {
            padding: 24px 15px;
          }
        }
      }
    }
  }
  }

  .page-main-header {
    .main-header-right {
      .nav-right {
        &.right-menu {
          ul {
            li {
              .user-header {
                img {
                  width: 90px !important;
                }
              }
            }
          }
        }
        .bookmark-flip {
          top: 205px !important;
        }
        > ul {
          > li {
            &:first-child {
              width: 50%;
            }
          }
        }
        .onhover-dropdown {
          .onhover-show-div {
            top: 50px;
          }
        }
      }
    }
  }
  .static-top-widget {
    .media-body {
      .icon-bg {
        top: 0;
      }
    }
  }
  .browser-widget {
    .media {
      .media-img {
        width: unset;
      }
    }
  }
  .weather-widget {
    .weather-widget-top {
      height: 240px;
    }
    .weather-widget-bottom {
      .col {
        .box {
          &:before {
            left: 0;
          }
          svg {
            width: 40px;
            height: 50px;
          }
        }
      }
    }
  }

  .mobile-clock-widget {
    #date {
      margin-top: 20px;
    }
  }
  .m-r-30 {
    margin-right: 20px;
  }
  .page-wrapper {
    .page-body-wrapper {
      .page-body,
      footer {
        margin-left: 0 !important;
      }
      footer {
        margin-left: 0;
      }
      .page-header {
        padding-top: 30px;
        padding-bottom: 30px;
        .row {
          .breadcrumb-right {
            .breadcrumb {
              float: none;
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
  .vertical-mobile-sidebar {
    right: 75px;
    top: 18px;
    .sidebar-bar {
      vertical-align: middle;
    }
  }
  .gallery {
    margin-bottom: -20px;
  }
  .my-gallery {
    figure {
      margin-bottom: 20px;
    }
  }
  #aniimated-thumbnials {
    figure {
      &:nth-child(12),
      &:nth-child(11),
      &:nth-child(10),
      &:nth-child(9) {
        margin-bottom: 20px;
      }
    }
  }
  .photoswipe-pb-responsive {
    margin-bottom: -20px;
    .custom-mesonry {
      > div {
        img {
          margin-bottom: 20px !important;
        }
      }
    }
  }
  // timeline 1
  .timeline {
    &::before {
      display: none;
    }
  }
  #cd-timeline {
    &::before {
      display: block;
    }
  }
  // rating css
  .editable-rating {
    .btn-position {
      top: 30px;
      right: 30px;
    }
  }
  // main header css
  .page-wrapper {
    .page-main-header {
      margin-left: 0;
      width: 100%;
      .main-header-left {
        padding: 20px 0 20px 20px;
        margin-left: 0;
      }
      .main-header-right {
        .nav-right {
          position: unset;
          padding: 0 20px;
          .notification {
            top: 10px;
          }
          > .mobile-toggle {
            cursor: pointer;
            display: flex;
            align-items: center;
            svg {
              vertical-align: middle;
              circle {
                color: $primary-color;
              }
            }
          }
          > ul {
            top: 65px;
            position: absolute;
            z-index: -1;
            background-color: $white;
            transition: all linear 0.3s;
            box-shadow: 0px 5px 15px -15px $common-shadow-color;
            border-top: 1px solid #f5f1fe;
            width: 100%;
            left: 0;
            padding: 0 20px;
            transform: translateY(-35px) scaleY(0);
            opacity: 0;
            visibility: hidden;
            &.open {
              z-index: 1;
              opacity: 1;
              transform: translateY(0px) scaleY(1);
              visibility: visible;
            }
            > li {
              padding: 10px 20px 11px 20px;
            }
          }
        }
        .mobile-sidebar {
          .switch-sm {
            .switch {
              width: 38px;
              height: 38px;
            }
          }
        }
      }
      .main-header-left {
        .logo-wrapper {
          img {
            width: 80px;
            margin-top: 0;
            margin-right: 15px;
          }
        }
      }
    }
    .page-body-wrapper {
      .iconsidebar-menu {
        top: 65px;
        .iconMenu-bar {
          height: calc(100vh - 65px);
          .iconbar-mainmenu {
            top: 65px;
            height: calc(100vh - 65px);
          }
        }
      }
    }
  }
  .page-main-header {
    .main-header-right {
      .nav-right {
        .profile-dropdown {
          top: 65px !important;
        }
      }
    }
  }
  // main sidebar css
  .page-wrapper {
    .page-body-wrapper {
      .sidebar {
        height: calc(100vh - 60px);
      }
      .page-sidebar {
        top: 60px;
        height: calc(100vh - 60px);
        ~ .page-body {
          margin-left: 0px;
        }
      }
      .page-sidebar-open {
        width: 250px;
      }
      &.sidebar-icon {
        .page-sidebar {
          ~ .page-body {
            margin-left: 0;
          }
        }
        &.sidebar-close {
          .page-sidebar {
            left: -150px;
          }
        }
      }
    }
  }
  .page-body-wrapper.sidebar-hover {
    .page-sidebar {
      &.open {
        margin-left: 6px !important;
      }
    }
    .page-body {
      margin-left: 0 !important;
      #customer-review {
        .owl-stage-outer {
          width: 100% !important;
        }
      }
    }
  }
  .page-wrapper {
    .page-body-wrapper {
      .page-header {
        .row {
          h3 {
            font-size: 22px;
          }
        }
        .pull-right {
          float: none;
          margin-top: 15px;
        }
      }
      .page-body {
        margin-top: 60px;
        padding: 0 15px;
      }
      .default-according {
        .card {
          .card-header,
          .card-body {
            padding: 0.75rem 1.25rem;
          }
        }
      }
      .card {
        .card-header,
        .card-body,
        .card-footer {
          padding: 30px;
          .tab-content {
            .m-t-30 {
              margin-top: 20px !important;
            }
            .m-b-30 {
              margin-bottom: 20px !important;
            }
          }
        }
      }
      .user-profile {
        .profile-img-style {
          padding: 20px;
          .img-container {
            margin-top: 20px;
          }
        }
        hr {
          margin: 20px 0;
        }
        .like-comment {
          margin-top: 20px;
        }
      }
      .right-sidebar {
        top: 133px;
      }
    }
  }
  // general widget page
  .crm-activity {
    > {
      li {
        + li {
          margin-top: 10px;
          padding-top: 10px;
        }
      }
    }
  }
  // add post page
  .add-post {
    form {
      .form-group {
        margin-bottom: 20px;
      }
    }
    .dropzone {
      margin-bottom: 20px;
    }
  }
  // avatar page
  .customers {
    &.avatar-group {
      margin-right: 20px;
    }
  }
  //social app page css
  .socialprofile {
    .social-btngroup {
      margin: 20px 0;
    }
    .social-group {
      margin-top: 20px;
    }
  }
  .social-status {
    form {
      .form-group {
        .form-control-plaintext {
          margin-bottom: 20px;
        }
      }
    }
    .media {
      margin-bottom: 20px;
    }
  }
  .timeline-content {
    p,
    .comments-box {
      margin-top: 20px;
    }
  }
  .social-chat {
    margin-top: 20px;
    .media-body {
      padding: 20px;
    }
    .your-msg,
    .other-msg {
      margin-bottom: 20px;
    }
  }
  .social-network {
    span {
      margin-bottom: 20px;
    }
  }
  .details-about {
    + .details-about {
      margin-top: 20px;
    }
  }
  .activity-log {
    .my-activity {
      + .my-activity {
        margin-top: 20px;
      }
    }
  }
  .new-users-social {
    margin-bottom: 20px;
  }
  // timeline small page css
  .timeline-small {
    .media {
      margin-bottom: 20px;
      .media-body {
        h6 {
          margin-bottom: 20px;
        }
      }
      .timeline-round {
        width: 45px;
        height: 45px;
        top: -14px;
        &.timeline-line-1 {
          &:after {
            bottom: -46px;
            height: 35px;
          }
        }
        &.small-line {
          &:after {
            bottom: -26px;
            height: 14px;
          }
        }
        &.medium-line {
          &:after {
            bottom: -41px;
            height: 28px;
          }
        }
        svg {
          top: 14px;
        }
      }
    }
  }
  // helper classes page start
  .helper-classes {
    padding: 20px;
  }
  // helper classes page ends
  .weather-widget-two {
    .bottom-whetherinfo {
      .whether-content {
        top: 54px;
      }
    }
  }
  .social-widget-card {
    .b-b-light {
      padding: 20px;
      margin-bottom: 20px;
    }
  }
  .ecommerce-widget {
    .progress-showcase {
      margin-top: 20px;
    }
  }
  .crm-overall {
    margin: 0 -20px -20px;
  }
  .product-page-main {
    padding: 20px;
  }
  .tabbed-card ul {
    padding: 18px 15px;
  }

  .tabbed-card {
    ul {
      top: 3px;
      padding: 18px 15px;
      &.border-tab-primary {
        &.nav-tabs {
          padding: 0;
          .nav-item {
            .nav-link {
              padding: 24px 15px;
            }
          }
        }
      }
    }
  }
  .tilt-showcase {
    .tilt-image {
      text-align: center;
    }
    .mt-4 {
      margin-top: 20px !important;
    }
  }
  .todo {
    .todo-list-wrapper {
      .mark-all-tasks {
        top: 30px !important;
        right: 30px !important;
      }
    }
  }
  .custom-card {
    padding-bottom: 0;
    .card-footer {
      padding: 0 15px 15px 15px !important;
    }
    .card-header {
      border: none;
    }
    .dashboard-card {
      padding: 0;
    }
    .card-profile {
      img {
        height: 155px;
      }
    }
  }
  .display-1 {
    font-size: 4rem;
  }
  .display-2 {
    font-size: 3.5rem;
  }
  .display-3 {
    font-size: 2.5rem;
  }
  .display-4 {
    font-size: 1.5rem;
  }

  .typography {
    .h1 {
      font-size: 34px;
    }
    .h2 {
      font-size: 30px;
    }
    .h3 {
      font-size: 26px;
    }
    .h4 {
      font-size: 22px;
    }
    .h5 {
      font-size: 18px;
    }
    .h6 {
      font-size: 15px;
    }
  }
  h1 {
    font-size: 34px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 26px;
  }
  h4 {
    font-size: 22px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 15px;
  }

  .flot-chart-container {
    height: 300px;
  }
  .card-absolute {
    .card-header {
      padding: 10px 15px !important;
    }
  }

  .comingsoon {
    .comingsoon-inner {
      .countdown {
        .time {
          width: 80px;
          height: 80px;
          font-size: 26px;
        }
      }
    }
  }

  footer {
    bottom: -20px;
  }
  .owl-theme {
    .owl-nav {
      &.disabled {
        & + .owl-dots {
          margin-bottom: -10px;
          margin-top: 15px;
        }
      }
    }
  }
  .card {
    .card-header {
      .card-header-right {
        top: 21px;
        right: 23px;
      }
    }
  }
  //user profile
  .user-profile {
    .hovercard {
      .info {
        .user-designation {
          margin-bottom: 20px;
        }
      }
    }
  }
  //blog
  .blog-single {
    .comment-box {
      .comment-social {
        li {
          padding-left: 15px;
        }
      }
    }
  }

  //error and maintenace
  .error-wrapper {
    padding: 35px 0;
    .sub-content {
      font-size: 14px;
      line-height: 25px;
      margin-top: 0px;
    }
    .error-heading {
      margin-top: 30px;
      .headline {
        font-size: 180px;
        margin-top: -30px;
      }
    }
    .maintenance-heading {
      margin-top: 30px;
    }
  }

  //search
  .search-page {
    .info-block {
      flex-wrap: wrap;
    }
    .tab-content {
      .pb-4 {
        padding-bottom: 20px !important;
      }
    }
  }
  .contact-form {
    padding-top: 10px !important;
    .theme-form {
      padding: 20px;
      margin-top: 27px;
      .form-icon {
        margin-top: -47px;
      }
    }
  }
  //crm general
  .user-status {
    &.product-chart {
      max-height: 518px;
      overflow: auto;
    }
  }
  // general widget
  .widget-joins {
    .media {
      .media-body {
        i {
          float: none !important;
        }
      }
    }
  }
  .xl-none {
    display: block;
  }
  //blog
  .top-radius-blog {
    width: 100%;
  }
  .sm-100-w {
    width: 100%;
    height: 100%;
  }
  .page-wrapper {
    .page-body-wrapper {
      .footer-fix {
        margin-left: 0px;
        padding-right: 15px;
        width: calc(100% - 0px);
      }
    }
  }
  // tab material
  .nav-material.nav-tabs {
    margin-bottom: 20px;
  }
  .tab-content {
    ~ .nav-tabs {
      &.nav-material {
        margin-top: 20px;
      }
    }
  }
  // search page
  .search-page {
    .info-block {
      + .info-block {
        padding-top: 20px;
        margin-top: 20px;
      }
    }
    #video-links {
      .embed-responsive + .embed-responsive {
        margin-top: 20px;
      }
    }
  }
  .lg-mt {
    margin-top: 20px;
  }
  .gallery-with-description {
    margin-bottom: -20px;
    a {
      > div {
        margin-bottom: 20px;
      }
    }
  }
  // clipboard
  .clipboaard-container {
    p {
      margin-bottom: 8px;
    }
  }
  // summer-note
  .m-b-30 {
    margin-bottom: 20px !important;
  }
  // email app
  .email-wrap {
    .email-left-aside {
      .email-app-sidebar {
        ul {
          li {
            hr {
              margin: 13px 0;
            }
          }
        }
      }
    }
  }
  // navs page
  .nav-md-mt {
    margin-top: 20px;
  }
  .navs-icon {
    padding: 20px;
    .main-section {
      padding-top: 20px;
    }
    .separator {
      margin: 20px 0;
    }
  }
  .nav-list {
    padding: 20px;
  }
  .navs-dropdown {
    button {
      margin-top: 20px;
    }
    .onhover-show-div {
      top: 68px;
    }
  }
  // animated modal page
  .animated-modal {
    .form-group {
      width: 100%;
      display: block;
    }
    .mr-4 {
      margin-right: 0 !important;
    }
    .animated-modal-md-mb {
      margin-bottom: 20px !important;
    }
  }
  // steps page
  .steps-md-mt {
    margin-top: 20px;
  }
  // button group page
  .btn-group-wrapper {
    .m-b-30 {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
  // datatable advance page
  #advance-5_wrapper {
    .dataTables_paginate {
      margin-bottom: 20px;
    }
  }
  // summer-note page
  .click2edit {
    & ~ .note-editor {
      &.note-frame {
        margin-bottom: 20px;
      }
    }
  }
  // call-chat page
  .chat-box {
    .chat-left-aside {
      .people-list {
        height: 483px;
      }
    }
  }
}
// login page

/* ========= responsive scss for 768 screen ========= */
@media only screen and (max-width: 767px) {

  .ngx-datatable {
    .datatable-body {
      .datatable-scroll {
        overflow: scroll;
        width: 100% !important;
      }
    }
  }
  .custom-datatable {
    .ngx-datatable {
      &.bootstrap {
        .datatable-body {
          .datatable-body-row {
            &.datatable-row-even, &.datatable-row-odd {
              width: 100% !important;
            }
          }
        }
      }
      &.fixed-header {
        .datatable-header {
          overflow: scroll;
          .datatable-header-inner {
            .datatable-header-cell {
              width: 100px !important;
            }
          }
        }
      }
      .datatable-body {
        .datatable-body-row {
          >div {
            white-space: nowrap;
          }
        }
      }
    }
  }
  .tabbed-card {
    ngb-tabset[class*="borderb-tab-"] {
      ul {
        position: relative;
        top: unset !important;
        right: unset;
        padding: 0;
      }
      .nav-tabs {
        .nav-item  {
          .nav-link {
            padding: 15px;
          }
        }
      }
    }
  }
  .external-events {
    margin-bottom: 20px;
  }
  .navbar-toggler {
    position: absolute;
    right: 0;
  }
  .copyright {
    text-align: center;
    .copy-right-text {
      text-align: center !important;
    }
  }
  .default-chartist-container {
    margin-bottom: 6px;
    &.ct-10,
    &.ct-11 {
      padding: 0;
      height: 200px;
      svg {
        height: 200px !important;
        .ct-labels foreignObject .ct-label {
          left: 0;
        }
      }
    }
  }

  .weather-widget {
    .weather-widget-bottom .col .box {
      padding: 10px;
      h5 {
        font-size: 14px;
      }
      svg {
        width: 30px;
        height: 40px;
      }
      h6 {
        font-size: 13px;
      }
      &:before {
        left: 10px;
        height: 50px;
        width: 50px;
      }
    }
    .weather-widget-top {
      height: 170px;
      .location {
        top: 40px;
        right: 40px;
      }
    }
  }
  .task-list {
    margin: 20px;
    li {
      font-size: 14px;
    }
  }
  .clock-details {
    .date {
      font-size: 30px;
    }
  }
  .card {
    margin-bottom: 30px;
  }
  .blog-box {
    .blog-details-main {
      .blog-social {
        li {
          padding: 0 12px;
        }
      }
    }
    .blog-details {
      .blog-social {
        li {
          &:first-child {
            padding-right: 25px;
          }
          + li {
            padding-left: 25px;
          }
        }
      }
    }
  }
  .footer-copyright {
    ~ div {
      text-align: center;
      p {
        float: none;
        margin-top: 5px;
      }
    }
  }
  // main header css
  .page-wrapper {
    .page-main-header {
      .main-header-left {
        width: 18%;
      }
      .main-header-right {
        .nav-right {
          > ul {
            > li {
              padding: 8px 10px 10px 10px;
              &:first-child {
                width: 35%;
              }
            }
          }
          .onhover-dropdown {
            .onhover-show-div {
              top: 50px;
            }
          }
          .bookmark-flip {
            top: 195px !important;
          }
          .profile-dropdown {
            top: 60px !important;
          }
          .notification-dropdown {
            &:before,
            &:after {
              right: 40px;
            }
          }
          .droplet-dropdown {
            left: -259px !important;
            &:before,
            &:after {
              right: 95px;
            }
          }
        }
      }
    }
  }
  // ngx chart
  .grid-pie-chart {
    &.flot-chart-container {
      height: 400px;
    }
  }
  // form wizard 4
  .wizard-4 {
    aw-wizard {
      &.vertical {
        flex-direction: unset;
        display: block;
        .wizard-steps {
          width: 100%;
          display: block;
          margin-top: 15px;
        }
      }
    }
    aw-wizard-navigation-bar {
      &.vertical {
        width: 100% !important;
      }
    }
  }
  // date-picker
  .custom-datepicker {
    ngb-datepicker {
      width: 270px;
    }
    .ngb-dp-navigation-select {
      .custom-select {
        width: 87px;
      }
    }
  }
  .mutliple-datepicker {
    ngb-datepicker,
    .dropdown-menu {
      width: 430px;
    }
    .ngb-dp-day {
      .btn-light {
        line-height: 1rem;
        width: 1rem;
        height: 1rem;
        font-size: 11.5px;
      }
    }
    .ngb-dp-day, .ngb-dp-week-number, .ngb-dp-weekday, .ngb-dp-arrow {
      .btn-link {
        .ngb-dp-navigation-chevron {
          width: 6px;
          height: 6px;
        }
      }
    }
    .custom-day {
      font-size: 11.5px;
      padding: 0;
      width: 1rem;
    }
  }
  // social app page css
  .user-profile {
    .hovercard {
      .user-image {
        .share-icons {
          right: 25px;
          top: -19px;
          li {
            .social-icon {
              width: 35px;
              height: 35px;
            }
          }
        }
      }
    }
  }
  .tabs-scoial {
    &.nav-material {
      &.nav-tabs {
        display: flex;
      }
    }
  }
  // timeline small page css
  .timeline-small {
    .media {
      .media-body {
        p {
          width: 100%;
        }
      }
    }
  }
  // icon-compact layout page
  .my-gallery {
    figure {
      &:nth-child(9),
      &:nth-child(10) {
        margin-bottom: 20px;
      }
    }
  }
  // chat
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-message {
          .text-box {
            .btn {
              font-size: 0;
              &:before {
                position: absolute;
                content: "\e661";
                font-family: $font-themify;
                font-size: 18px;
                top: 11px;
                left: 20px;
              }
            }
          }
        }
      }
    }
    .nav-material {
      &.nav-tabs {
        display: flex;
      }
    }
    .chat-menu {
      .nav-tabs {
        .nav-item {
          a {
            height: 50px;
          }
        }
      }
    }
  }
  .clockpicker-align-top {
    left: 55px !important;
    top: 470px !important;
    .arrow {
      display: none;
    }
  }
  .reader {
    margin-top: 15px;
    height: auto !important;
  }
  .range-slider {
    &.theme-form {
      .form-group {
        margin-bottom: 0;
      }
    }
  }
  .note-editor {
    .btn-group {
      .btn {
        font-size: 11px;
      }
    }
  }
  .user-status {
    tbody {
      tr {
        td {
          min-width: 284px;
          & + td {
            min-width: unset;
            & + td {
              min-width: 100px;
            }
          }
        }
      }
    }
  }
  .text-md-right {
    text-align: right;
  }
  .star-ratings {
    .stars {
      padding: 0;
    }
  }
  .form-inline {
    &.d-inline-block {
      display: block !important;
      width: 100%;
      .form-control {
        margin-bottom: 10px;
      }
    }
  }
  .reset-password-box {
    width: 500px;
    margin: 0 auto;
    .card {
      padding: 20px;
    }
  }
  .custom-card {
    .card-profile {
      img {
        height: 136px;
        bottom: 0;
      }
    }
  }
  .error-wrapper {
    .maintenance-icons {
      li {
        i {
          color: $light-color;
          font-size: 30px;
        }
        &:nth-child(2) {
          i {
            font-size: 60px;
            margin-top: -10px;
            margin-left: 70px;
          }
        }
        &:nth-child(3) {
          i {
            font-size: 90px;
            margin-right: 80px;
          }
        }
      }
    }
  }
  .authentication-main {
    .auth-innerright {
      .social-media {
        li {
          font-size: 14px;
        }
      }
      min-height: unset;
      padding: 0;
    }
  }
  .loader-box {
    justify-content: center;
  }
  .btn-group-wrapper {
    text-align: center;
    .m-b-30 {
      margin-bottom: 15px !important;
    }
  }

  .btn-group-showcase {
    text-align: center;
    .btn-group {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
  .btn-group {
    .btn {
      font-size: 14px;
      padding: 0.375rem 1.2rem;
    }
  }
  .animate-widget {
    .text-center {
      display: none;
    }
  }
  //general widget page
  .user-status {
    &.product-chart {
      max-height: 100%;
    }
    table {
      thead {
        tr {
          th {
            &:nth-child(3) {
              min-width: 124px;
            }
          }
        }
      }
    }
  }
  /* font-awesom icon page */
  .icon-hover-bottom {
    .form-group {
      input {
        min-width: 270px;
      }
    }
  }

  //user profile
  .user-profile {
    .hovercard {
      .cardheader {
        height: 350px;
      }
      .info {
        padding: 35px;
        .ttl-info {
          margin-bottom: 20px;
        }
        .ttl-sm-mb-0 {
          margin-bottom: 0;
        }
      }
    }
  }
  //blog
  .blog-single {
    .comment-box {
      .media {
        h6 {
          margin-bottom: 10px;
        }
        img {
          margin-right: 30px;
        }
      }
      .comment-social {
        margin-bottom: 5px;
        li:first-child {
          padding-left: 0;
          padding-right: 15px;
        }
      }
    }
  }

  //material tab
  .nav-material.nav-tabs {
    display: inline-block;
    width: 100%;
    .nav-item {
      float: left;
    }
  }

  //search
  .search-page {
    #image-links {
      .info-block {
        &.m-t-30 {
          margin-top: 0 !important;
        }
      }
    }
  }

  //authentication
  .authentication-main {
    .authentication-box {
      width: 100%;
    }
    .auth-innerright {
      display: inherit;
    }
  }

  //default general
  .map-chart {
    .chart-container {
      height: 300px;
    }
  }
  .candidcahrt {
    height: 250px;
  }

  //chart widget
  .bar-chart-widget {
    .earning-details {
      i {
        right: 0;
      }
    }
  }
  .serial-chart .chart-container {
    height: 300px;
  }
  .bottom-content {
    padding: 10px;
    p {
      font-size: 12px;
    }
  }
  // icons
  .icon-hover-bottom {
    .icon-popup {
      div {
        .flag-icon {
          width: 30px;
          height: auto;
        }
      }
      .form-group {
        input {
          min-width: 40%;
        }
      }
    }
  }

  //footer
  .footer-links {
    text-align: center;
  }
  .footer-copyright {
    text-align: center !important;
  }
  .jvector-map-height {
    height: 150px;
  }
  .sm-left-text {
    text-align: left !important;
  }
  // email app
  .email-wrap {
    .email-right-aside {
      .email-content {
        .email-top {
          .d-flex {
            padding-bottom: 0;
          }
          .float-right {
            float: left !important;
          }
        }
        .email-wrapper {
          .attachment {
            text-align: center;
            ul {
              li {
                &:last-child {
                  padding-right: 0;
                }
                img {
                  width: 140px;
                  height: 140px;
                }
              }
            }
          }
        }
      }
    }
  }
  // alert page
  .alert-dismissible {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 294px;
    }
  }
  // button group page
  .button-group-mb-sm {
    margin-bottom: 5px;
  }
  // table components page
  .card-block {
    .table-responsive {
      .table {
        tbody {
          tr {
            td {
              span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100px;
              }
            }
          }
        }
      }
      .progress-content {
        tbody {
          tr {
            td {
              span {
                text-overflow: ellipsis;
                white-space: normal;
                max-width: 50px;
              }
            }
          }
        }
      }
      .checkbox-td-width,
      .radio-first-col-width {
        tbody {
          tr {
            td {
              min-width: 200px !important;
            }
          }
        }
      }
    }
  }
  // chat page
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-msg-box {
          height: auto;
        }
      }
    }
    .chat-menu {
      .nav-tabs {
        .nav-item {
          width: 32.33%;
        }
      }
      .people-list {
        ul.list {
          max-height: 433px;
          margin-bottom: 15px;
        }
      }
      #info-profile,
      #info-contact {
        margin-bottom: 15px;
      }
    }
  }
  // user-profile page
  .hovercard {
    .info {
      .follow {
        .text-md-right {
          text-align: center;
        }
      }
    }
  }
  .profile-img-style {
    .like-comment-sm-mb {
      margin-bottom: 20px;
    }
  }

  // forget-password page
  .reset-password-box {
    .theme-form {
      .form-group {
        .btn {
          margin-top: 15px !important;
        }
      }
    }
  }
  // gallery page
  .lg-outer {
    .lg-actions .lg-next,
    .lg-actions .lg-prev {
      top: 48%;
    }
  }
  // product page
  .product-sidebar {
    &.open {
      top: -35px;
    }
  }
  .feature-products {
    .grid-options {
      display: none !important;
    }
    .select2-drpdwn-product {
      float: left;
      margin-right: 10px;
    }
    .text-right {
      text-align: left !important;
    }
  }
}
/* ========= responsive scss for 575 screen ========= */
@media only screen and (min-width: 576px) {
  .search-knowledge {
    .form-group {
      display: block;
      flex: auto;
    }
  }
}
@media only screen and (max-width: 575px) {
  .search-page {
    .blog-box {
      img {
        height: 200px;
      }
    }
  }
  .cal-month-view {
    .cal-day-number {
      font-size: 14px !important;
    }
    .cal-cell-row {
        overflow: hidden;
    }
  }
  .contact-profile-form {
    width: 100%;
  }
  .contact-filter {
    display: block;
    .btn {
      margin-top: 15px;
    }
  }
  .dropup {
    .dropdown-menu {
      min-width: 130px;
    }
  }
  .btn {
    padding: 6px 15px;
  }
  #modal-gallery-container {
    figcaption {
      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;

      }
    }
  }
  .order-history {
    &.custom-datatable {
      .ngx-datatable {
        &.bootstrap {
          .datatable-body {
            .datatable-body-row {
              .datatable-body-cell {
                width: 100px !important;
              }
            }
          }
        }
      }
    }
  }
  .border-tab-primary {
    &.nav-tabs, .nav-tabs {
      display: block;
    }
  }
  .btn-group-toggle {
    .btn {
      font-size: 13px;
      padding: 6px 10px;
    }
  }
  .cal-date-widget {
    .p-50 {
      padding: 30px;
    }
  }
  .todo {
    .action-box {
      &.large {
        .icon {
          vertical-align: -3px !important;
        }
      }
    }
  }
  .page-wrapper .page-main-header .main-header-right .nav-right > ul {
    top: 61px;
  }
  .btc-earning {
    height: 250px;
    padding-top: 0px;
  }
  .btc-overview span {
    font-size: 120px !important;
  }
  .btc-buy-sell li {
    padding-bottom: 25px;
  }
  .btc-buy {
    .font-secondary {
      margin-bottom: 20px;
    }
  }
  .add-arrow .more-btn .btn-light {
    display: none;
  }
  .mobile-clock-widget .clock-details {
    margin-top: 50px;
  }
  .alert-dismissible {
    p {
      max-width: 209px;
    }
  }
  .m-r-30 {
    margin-right: 15px;
  }
  .welcome-popup {
    .modal-dialog {
      max-width: 376px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .search-knowledge {
    &.pull-right {
      float: left;
    }
  }
  // search page
  .search-page {
    .nav-tabs {
      .tab-content {
        text-align: left;
      }
    }
  }
  // main header css
  .page-wrapper {
    .page-main-header {
      .main-header-left {
        padding: 20px 0 20px 15px;
        width: 20%;
        .logo-wrapper {
          img {
            width: 60px;
          }
        }
      }
      .main-header-right {
        .nav-right {
          padding: 0 15px;
          ul {
            li {
              svg {
                width: 16px;
                height: 16px;
              }
            }
          }
          .onhover-dropdown {
            .onhover-show-div {
              left: 96px;
            }
          }
          &.right-menu {
            ul {
              justify-content: center;
              li {
                .user-header {
                  .media-body {
                    display: none;
                  }
                  img {
                    width: 37px;
                    margin-right: 0 !important;
                  }
                }
              }
            }
          }
          .profile-dropdown {
            left: -80px !important;
          }
          > ul {
            padding: 10px;
            > li {
              padding: 0 8px;
              &:first-child {
                width: auto !important;
                margin: 0 !important;
              }
            }
            .search-form {
              input {
                font-size: 14px;
              }
              .form-control-plaintext {
                top: 50px;
                position: absolute;
                transition: all linear 0.3s;
                left: -10px;
                background-color: #fff;
                transform: translateY(-35px) scaleY(0);
                opacity: 0;
                visibility: hidden;
                width: 180px;
                padding: 10px 10px 10px 15px;
                border: 1px solid $primary-color;
                &.open {
                  transform: translateY(0px) scaleY(1);
                  opacity: 1;
                  visibility: visible;
                  transition: all linear 0.3s;
                }
              }
              .form-group {
                &:before,
                &:after {
                  display: none;
                }
              }
            }
          }
          .bookmark-dropdown {
            width: auto !important;
          }
          .droplet-dropdown {
            left: -91px !important;
            width: 300px;
            li {
              .row {
                .droplet-main {
                  padding: 15px;
                  &:nth-child(3n) {
                    border-right: 1px solid $light-semi-gray;
                  }
                  &:nth-child(2n) {
                    border-right: none !important;
                  }
                }
              }
            }
            &:before,
            &:after {
              right: 185px;
            }
          }
          .notification-dropdown {
            left: -85px !important;
            &.onhover-show-div {
              width: 260px;
              &:before,
              &:after {
                right: 152px !important;
              }
            }
          }
        }
        .mobile-sidebar {
          .switch-sm {
            .switch {
              margin-top: 3px;
              width: 34px;
              height: 34px;
              svg {
                width: 18px;
              }
            }
          }
        }
      }
    }
    .page-body-wrapper {
      .iconsidebar-menu {
        top: 100px;
        .iconMenu-bar {
          height: calc(120vh - 61px);
          //margin-top: 40px;
          .iconbar-mainmenu {
            top: 100px;
            height: calc(120vh - 61px);
          }
        }
      }
      .page-header {
        .btn-group {
          .btn {
            padding: 8px 30px;
          }
        }
      }
      .right-sidebar {
        top: 120px;
      }
    }
  }
  .Typeahead {
    width: 15px;
    margin-top: 0;
  }
  .Typeahead-menu {
    width: 250px;
    top: 93px;
  }
  .flip-card {
    .Typeahead-menu {
      top: 35px;
      width: 220px;
    }
  }
  .Typeahead-spinner {
    top: 54px;
    right: -144px;
  }
  .twitter-typeahead {
    position: absolute !important;
    bottom: 0;
    z-index: -1;
  }
  .mobile-search {
    z-index: 1;
  }
  .xs-mt-timer-steps {
    margin-top: 15px;
  }
  .note {
    min-width: 235px !important;
    min-height: 235px !important;
  }
  // timeline 1 page css
  vertical-timeline {
    .timeline {
      &::before {
        display: none;
      }
      .cd-container {
        .timeline-content {
          padding: 15px 15px;
        }
        .timeline-item {
          &:nth-child(even) {
            .timeline-content {
              padding: 40px 15px 15px 15px;
              .inner-content {
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }
  .timeline {
    .timeline-img {
      left: 17px !important;
    }
    #cd-timeline {
      &::before {
        left: 16px;
      }
    }
    .timeline-content {
      margin-left: 55px !important;
    }
    .timeline-item {
      &:nth-child(odd) {
        .timeline-content {
          .inner-content {
            margin-top: 40px !important;
          }
        }
      }
      .inner-content {
        h4 {
          font-size: 18px;
        }
      }
    }
    .date {
      padding: 6px;
    }
  }
  // product page
  .product-modal {
    .product-box {
      .product-details {
        padding: 15px;
      }
    }
  }
  // product list
  .product-list-custom {
    .ngx-datatable {
      &.fixed-header {
        .datatable-header {
          overflow: auto;
          .datatable-header-inner {
            white-space: unset;
            .datatable-header-cell {
              white-space: unset;
              overflow: auto;
              text-overflow: unset;
              width: 100px !important;
            }
          }
        }
      }
      .datatable-body-row,
      .datatable-row-center,
      .datatable-header-inner {
        display: flex !important;
        width: 536px !important;
        overflow: auto;
      }
      .datatable-body-cell,
      .datatable-header-cell {
        min-width: 150px;
        overflow-x: auto;
      }
    }
  }
  // apex chart
  #piechart,
  #donutchart {
    svg {
      .apexcharts-legend {
        display: none;
      }
    }
  }
  // datatable
  .ngx-datatable {
    .datatable-footer {
      .datatable-footer-inner {
        display: block !important;
        height: auto !important;
        text-align: center;
      }
      .page-count,
      .datatable-pager {
        flex: 1 1 100% !important;
      }
    }
    &.bootstrap {
      .datatable-footer {
        .datatable-pager {
          .datatable-icon-left, .datatable-icon-skip, .datatable-icon-right, .datatable-icon-prev {
            vertical-align: unset;
            line-height: 24px;
          }
        }
      }
    }
  }
  .custom-datatable {
    .ngx-datatable {
      &.bootstrap {
        .datatable-footer {
          .datatable-pager {
            a {
              height: 27px;
              min-width: 27px;
              line-height: 28px;
            }
          }
        }
      }
    }
    input {
      width: 100%;
    }
  }
  // image cropper
  .image-cropper-btn {
    input {
      width: 100%;
    }
  }
  // range slider
  .ng5-slider-tick-legend {
    transform: rotate(45deg) !important;
  }
  // tabset
  .tabset-group-btn-xs {
    .btn-position {
      padding: 6px 5px;
      font-size: 13px;
    }
  }
  // list page
  .vertical-tab {
    .nav-tabs {
      width: 100%;
      margin-right: 0;
      .nav-link {
        border: 1px solid rgba(0, 0, 0, 0.125);
      }
    }
    .tab-content {
      width: 100%;
      margin-top: 15px;
    }
  }
  // rating
  .star {
    font-size: 25px;
  }
  .editable-rating {
    .rating-size {
      margin-bottom: 40px;
    }
    .btn-position {
      top: 60px;
      left: 25px;
      right: unset;
    }
  }
  // datepicker
  .mutliple-datepicker {
    ngb-datepicker,
    .dropdown-menu {
      width: 223px;
      .ngb-dp-months {
        display: block;
        .ngb-dp-month {
          width: 100%;
        }
      }
      ngb-datepicker-month-view {
        padding-left: 49px;
      }
      .ngb-dp-navigation-select {
        .custom-select {
          width: 60px;
        }
      }
    }
    .ngb-dp-header {
      padding: 15px 0 5px 0;
    }
  }
  // general widget page css
  .mobile-clock-widget {
    #date {
      margin-top: 15px;
    }
  }
  .social-widget-card {
    .b-b-light {
      padding: 15px;
      margin-bottom: 15px;
    }
  }
  .weather-widget-two {
    .bottom-whetherinfo {
      .whether-content {
        top: 68px;
      }
    }
  }
  .cal-date-widget {
    .cal-datepicker {
      margin-top: 15px;
    }
  }
  // Add post page css
  .add-post {
    form {
      .m-checkbox-inline {
        label {
          margin-right: 15px;
        }
      }
      .form-group {
        margin-bottom: 15px;
      }
    }
    .dropzone {
      margin-bottom: 15px;
    }
  }
  // avatar page css
  .customers {
    &.avatar-group {
      margin-right: 15px;
    }
  }
  .vertical-mobile-sidebar {
    top: 19px;
  }
  //notify index page
  .alert-theme {
    i {
      margin-right: 10px !important;
    }
    button {
      top: 2px !important;
    }
  }
  .chart-widgets-small {
    .chart-container {
      height: 282px !important;
    }
  }
  .bar-chart-widget {
    .earning-details {
      i {
        right: -30px;
      }
    }
  }
  .widget-joins {
    &:before {
      display: none;
    }
    .pl-0 {
      padding-left: 15px !important;
    }
    .border-after-xs {
      &:after {
        content: "";
        position: absolute;
        height: 1px;
        width: 94%;
        background-color: $light-semi-gray;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }
  .clockpicker-align-top {
    left: 45px !important;
    top: 406px !important;
  }
  .gallery {
    margin-bottom: -15px;
  }
  .my-gallery {
    figure {
      margin-bottom: 15px;
      &:nth-child(9),
      &:nth-child(10) {
        margin-bottom: 15px;
      }
    }
    &.gallery-with-description {
      figure {
        &:last-child {
          margin-bottom: 20px;
        }
      }
    }
  }
  #aniimated-thumbnials {
    figure {
      &:nth-child(12),
      &:nth-child(11),
      &:nth-child(10),
      &:nth-child(9) {
        margin-bottom: 15px;
      }
    }
  }
  .photoswipe-pb-responsive {
    margin-bottom: -15px;
    .custom-mesonry {
      > div {
        img {
          margin-bottom: 15px !important;
          width: 100% !important;
        }
      }
    }
  }
  // social app page css
  .avatar-showcase {
    .pepole-knows {
      text-align: center;
      ul {
        li {
          margin-right: 14px;
        }
      }
    }
  }
  .social-app-profile {
    .hovercard {
      .user-image {
        .avatar {
          margin-top: -56px;
          img {
            width: 100px;
            height: 100px;
          }
        }
        .icon-wrapper {
          left: 52%;
          top: 10px;
          height: 30px;
          width: 30px;
          font-size: 15px;
          &:hover {
            font-size: 12px;
          }
        }
        .share-icons {
          right: 15px;
          li {
            margin-right: 0px;
          }
        }
      }
      .info {
        .tabs-scoial {
          .user-designation {
            border-top: none;
            border-bottom: none;
            margin-bottom: 0;
            padding: 0px 0px;
          }
        }
      }
    }
    .tabs-scoial {
      margin-bottom: 0 !important;
    }
  }
  .socialprofile {
    .social-btngroup {
      margin: 15px 0;
    }
    .social-group {
      margin-top: 15px;
    }
  }
  .social-status {
    form {
      .form-group {
        .form-control-plaintext {
          margin-bottom: 15px;
        }
      }
    }
    .media {
      margin-bottom: 15px;
    }
  }
  .new-users-social {
    margin-bottom: 15px;
  }
  // social app css
  .social-app-tab {
    .media-body,
    .nav {
      text-align: left;
    }
    .tabs-scoial {
      ul {
        &.nav-tabs {
          display: flex;
          li {
            &.nav-item {
              padding: 0;
            }
          }
        }
      }
    }
    .custom-card,
    .pepole-knows,
    .photos,
    .social-group {
      ul {
        li {
          position: relative;
          top: 0;
        }
      }
    }

    .social-group {
      ul {
        li {
          &:nth-child(3) {
            margin-left: -22px;
          }
        }
      }
    }
  }
  .market-tabs {
    .nav {
      .nav-item {
        .nav-link {
          height: 54px;
        }
      }
    }
  }
  .tabs-scoial {
    position: relative;
    padding-top: 93px;
    ngb-tabset {
      > ul {
        > li {
          &:nth-child(3) {
            position: absolute;
            top: 40px;
            height: unset;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
        }
      }
    }

    &.nav-material {
      &.nav-tabs {
        .nav-item {
          .nav-link {
            padding: 10px 18px;
          }
        }
      }
    }
  }
  .timeline-content {
    p,
    .comments-box {
      margin-top: 15px;
    }
    .comment-number {
      i {
        margin-right: 15px;
      }
    }
  }
  .social-chat {
    margin-top: 15px;
    .media-body {
      padding: 15px;
    }
    .your-msg,
    .other-msg {
      margin-bottom: 15px;
    }
  }
  .social-network {
    span {
      margin-bottom: 15px;
    }
  }
  .social-list {
    .media {
      margin-bottom: 15px;
    }
  }
  .details-about + .details-about {
    margin-top: 15px;
  }
  .your-details-xs {
    margin-top: 15px;
  }
  .social-header {
    h5 {
      span.pull-right {
        float: right !important;
        margin-top: 0 !important;
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .activity-log {
    .my-activity {
      + .my-activity {
        margin-top: 15px;
      }
    }
  }
  .photos {
    ul {
      li {
        width: 26%;
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
  // timeline small page css
  .timeline-small {
    .media {
      margin-bottom: 15px;
      .media-body {
        h6 {
          margin-bottom: 15px;
        }
        p {
          -webkit-line-clamp: 2;
        }
      }
      .small-line {
        ~ .media-body {
          p {
            -webkit-line-clamp: 1;
          }
        }
      }
      .timeline-round {
        &.timeline-line-1 {
          &:after {
            bottom: -39px;
            height: 30px;
          }
        }
        &.small-line {
          &:after {
            bottom: -17px;
            height: 8px;
          }
        }
        &.medium-line {
          &:after {
            bottom: -40px;
            height: 34px;
          }
        }
      }
    }
  }
  .iconsidebar-menu {
    &.iconbar-second-close {
      ~ .page-body {
        .timeline-small {
          .media {
            .timeline-round {
              &.timeline-line-1 {
                &:after {
                  bottom: -39px;
                  height: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
  // helper classes page start
  .helper-classes {
    padding: 15px;
  }
  // helper classes page ends
  .nav-tabs {
    .nav-item {
      &.show {
        .nav-link {
          border-top: 0;
          border-bottom: 0;
        }
      }
    }
    .nav-link {
      border-top: 0;
      border-bottom: 0;
      &:hover,
      &.active,
      &:focus {
        border-top: 0;
        border-bottom: 0;
      }
    }
  }

  .nav-material {
    &.nav-tabs {
      .nav-item {
        width: 100% !important;
      }
    }
    .weather-widget-two {
      .bottom-whetherinfo {
        .whether-content {
          top: 35px;
        }
      }
    }
    .social-widget-card {
      .b-b-light {
        padding: 15px;
        margin-bottom: 15px;
      }
    }
    .ecommerce-widget {
      .progress-showcase {
        margin-top: 15px;
      }
    }
  }
  .nav {
    display: inherit;
    text-align: center;
    border-bottom: none;
  }
  .market-tabs {
    .nav-tabs {
      display: flex;
    }
  }
  // tab material start
  .nav-material.nav-left {
    .nav-link {
      text-align: center;
      border-left: none;
    }
  }
  .nav-material.nav-right {
    .nav-link {
      text-align: center;
      border-right: none;
    }
  }
  .nav-material.nav-tabs {
    margin-bottom: 10px;
  }
  .tab-content {
    ~ .nav-tabs {
      &.nav-material {
        margin-top: 10px;
      }
    }
  }
  // tab material end
  .nav-pills {
    text-align: center;
  }
  .crm-overall {
    margin: 0 -15px -15px;
  }
  .product-page-main {
    padding: 15px;
    .m-t-15 {
      margin-top: 0 !important;
      .btn {
        margin-top: 10px;
      }
    }
  }
  .tabbed-card {
    ul {
      margin-bottom: 15px;
      display: block;
      &.border-tab-primary {

        &.nav-tabs {
          .nav-item {
            .nav-link {
              padding: 5px 15px 10px;
            }
          }
        }
      }
      padding: 10px 0 5px 0;
      position: relative;
      width: 100%;
      left: 0;
    }
    ngb-tabset[class*="borderb-tab-"] {
      .nav-tabs {
        margin-bottom: 10px;
        display: block;
        .nav-item  {
          .nav-link {
            padding: 10px;
          }
        }
      }
    }
  }
  .todo {
    .action-box {
      &.large {
        height: 25px !important;
        width: 25px !important;
        .icon {
          font-size: 14px !important;
        }
      }
    }
    .todo-list-wrapper {
      #todo-list {
        li {
          .task-container {
            .task-label {
              font-size: 14px !important;
            }
          }
        }
      }
      .mark-all-tasks {
        top: 23px !important;
        right: 25px !important;
      }
    }
  }
  .redial-chart-block {
    text-align: center;
  }
  #nav-tabContent {
    margin-top: 15px;
  }
  .reset-password-box {
    width: 430px;
  }
  .auth-bg {
    padding: 25px 15px;
  }
  .auth-bg-effect {
    display: none;
  }
  .date-picker {
    .text-right {
      text-align: left !important;
    }
  }
  ul {
    &.pagination {
      li {
        &:before {
          line-height: 3;
        }
      }
    }
  }
  .page-wrapper {
    .page-body-wrapper {
      .user-profile {
        .profile-img-style {
          padding: 15px;
          .img-container {
            margin-top: 15px;
          }
        }
        hr {
          margin: 15px 0;
        }
        .like-comment {
          margin-top: 15px;
        }
      }
    }
  }
  .jvector-map-height {
    height: 250px;
  }
  .user-profile {
    .order-sm-0 {
      order: -1;
    }
    .hovercard {
      .info {
        .ttl-info {
          text-align: center !important;
        }
        .ttl-xs-mt {
          margin-top: 20px;
        }
      }
    }
  }
  .icon-hover-bottom {
    .form-group {
      display: block;
    }
    .icon-popup {
      padding: 15px;
      display: block;
    }
    .btn {
      margin-top: 15px;
    }
    svg {
      &.climacon {
        height: 50px;
        width: 50px;
      }
    }
  }
  .blog-box {
    &.blog-shadow {
      &:before {
        box-shadow: inset 0px -100px 100px -13px rgba(0, 0, 0, 0.6);
      }
    }
    .blog-details {
      p,
      .single-blog-content-top {
        margin-top: 15px;
      }
      h4 {
        margin-top: 15px;
      }
      .blog-social {
        margin-top: 15px;
        li {
          & + li {
            padding-left: 20px;
            > span {
              display: none;
            }
          }
          &:first-child {
            margin-bottom: 10px;
            padding-right: 20px;
          }
        }
      }
    }
  }
  .comment-box {
    padding-top: 40px;
    padding-bottom: 40px;
    ul {
      ul {
        margin-left: 30px;
      }
    }
    li {
      .media {
        display: block;
        img {
          height: 50px;
          width: 50px;
          padding: 3px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .card {
    .card-header {
      .card-header-right {
        top: 15px;
        right: 20px;
      }
    }
  }
  .ace-editor {
    height: 320px;
  }
  .gallery {
    > a {
      margin-bottom: 15px;
      text-align: center;
    }
  }
  .card {
    .card-header {
      h5 {
        font-size: 17px;
      }
    }
  }
  .theme-form {
    .form-group {
      margin-bottom: 15px;
    }
  }
  .page-wrapper {
    .page-body-wrapper {
      .default-according {
        .card {
          .card-header,
          .card-body,
          .card-footer {
            padding: 0.75rem 1.25rem;
          }
        }
      }
      .card {
        margin-bottom: 25px;
        .card-header,
        .card-body,
        .card-footer {
          padding: 25px;
          .tab-content {
            .m-t-30 {
              margin-top: 15px !important;
            }
            .m-b-30 {
              margin-bottom: 15px !important;
            }
          }
        }
      }
      .accordion {
        > .card {
          margin-bottom: 10px;
        }
      }
      .page-body {
        padding: 0 !important;
      }
      .page-header {
        padding-top: 20px;
        padding-bottom: 20px;
        .row {
          h3 {
            font-size: 20px;
          }
        }
      }
    }
    .search-form {
      .form-group {
        margin-right: 0;
      }
    }
  }
  /* alert responsive css start here */
  $alert-name: primary, secondary, success, danger, warning, info, light, dark;
  $alert-color: $primary-color, $secondary-color, $success-color, $danger-color, $warning-color, $info-color,
    $light-color, $dark-color;

  @each $var in $alert-name {
    $i: index($alert-name, $var);
    .alert-#{$var}.inverse {
      background-color: transparent;
    }
  }
  .alert {
    .close {
      height: 100%;
    }
  }
  //user profile
  .user-profile {
    hr {
      margin: 15px 0;
    }
    .hovercard {
      .cardheader {
        height: 300px;
      }
      .info {
        padding: 20px;
        .user-designation {
          border-top: 1px solid $light-color;
          border-bottom: 1px solid $light-color;
          margin-bottom: 15px;
          padding: 15px 0px;
        }
      }
      .social-media {
        a {
          margin-right: 0px;
          font-size: 16px;
        }
      }
      .follow {
        .follow-num {
          font-size: 20px;
        }
      }
    }
    .profile-img-style {
      .pictures {
        img {
          margin-bottom: 10px;
        }
      }
    }
  }

  //user card
  .custom-card {
    .card-footer {
      > div {
        h6 {
          font-size: 14px;
          font-weight: 600;
        }
        h3 {
          font-size: 24px;
        }
      }
    }
  }
  // megaoption page responsive scss
  .mega-inline {
    display: block;
  }
  .megaoptions-border-space-sm {
    padding-bottom: 30px !important;
  }
  //blog
  .blog-box.blog-shadow {
    .blog-details {
      padding: 15px;
      p {
        margin-bottom: 0;
      }
      h4 {
        margin-bottom: 10px;
      }
    }
  }
  .blog-box {
    &.blog-list {
      .blog-details {
        padding-top: 0;
      }
    }
    .blog-details {
      padding: 20px;
    }
    .blog-date {
      span {
        font-size: 25px;
      }
    }
  }
  .blog-single {
    .blog-box {
      .blog-details {
        padding: 0;
      }
    }
  }

  //error and maintenace
  .error-wrapper {
    padding: 20px 0;
    .btn {
      margin-top: 15px;
    }
    .img-100 {
      width: 50px !important;
    }
    .error-heading {
      margin-top: 20px;
      .cloud-second {
        margin-top: -60px;
      }
      .headline {
        font-size: 125px;
      }
    }
    .maintenance-heading {
      .cloud-second {
        display: none;
      }
      .headline {
        font-size: 25px;
        margin-top: -10%;
        letter-spacing: 2px;
      }
    }
  }

  //coming soon
  .comingsoon {
    .comingsoon-inner {
      .coming-soon-bottom-link {
        margin-top: 20px;
        line-height: 1;
      }
      h5 {
        margin-bottom: 20px;
        margin-top: 20px;
      }
      .countdown {
        padding: 20px 0px;
        ul li {
          margin: 0 7px;
        }
        .title {
          font-size: 12px;
        }
        .time {
          width: 55px;
          height: 55px;
          line-height: 55px;
          font-size: 16px;
          margin: 0 auto;
        }
      }
    }
  }
  //chart
  .flot-chart-container {
    height: 300px;
  }
  .small-chart-widget {
    .chart-container {
      height: 134px;
    }
  }
  .status-widget {
    .text-sm-right {
      text-align: right;
    }
  }
  // navs page
  .nav-md-mt {
    margin-top: 15px;
  }
  .navs-icon {
    padding: 15px;
    .main-section {
      padding-top: 15px;
    }
    .separator {
      margin: 15px 0;
    }
  }
  .nav-list {
    padding: 15px;
  }
  .navs-dropdown {
    button {
      margin-top: 15px;
    }
    .onhover-show-div {
      top: 64px;
    }
  }
  .lg-mt {
    margin-top: 15px;
  }
  // scroll reval
  #aniimated-thumbnials {
    a {
      &:last-child {
        img {
          margin-bottom: 15px;
        }
      }
    }
  }
  // tilt
  .tilt-showcase {
    .mt-4 {
      margin-top: 15px !important;
    }
    ol {
      .m-b-20 {
        margin-bottom: 15px !important;
      }
    }
  }
  // tag-pills page
  span:last-child.tag-pills-sm-mb {
    margin-top: 3px;
  }
  // alert page

  // animated modal page
  .animated-modal {
    .form-group {
      .form-control {
        width: 50%;
        margin: 0 auto;
      }
    }
    .animated-modal-md-mb {
      margin-bottom: 15px !important;
    }
  }
  // steps page
  .steps-md-mt {
    margin-top: 15px;
  }
  .steps-sizing-sm-mb {
    margin-bottom: 15px;
  }
  // touchspin page
  .bootstrap-touchspin {
    .touchspin-vertical-tab {
      .input-group {
        width: 96% !important;
      }
    }
  }
  // datatable API page
  #API-chield-row_wrapper {
    #API-chield-row {
      tbody {
        tr {
          td {
            &:first-child {
              min-width: 20px;
            }
          }
        }
      }
    }
  }
  // chart-flot page
  .chart-block {
    .flot-chart-container {
      p#choices {
        width: 100%;
      }
      #toggling-series-flot {
        width: 100% !important;
      }
    }
  }
  // summer-note page
  .click2edit {
    & ~ .note-editor {
      &.note-frame {
        margin-bottom: 15px;
      }
    }
  }
  // chat page
  .chat-box {
    .chat-history {
      .call-content {
        > div {
          padding-top: 60px;
        }
      }
    }
    .chat-left-aside {
      .people-list {
        height: auto;
      }
    }
  }
  .call-chat-sidebar {
    max-width: 100%;
    width: 100%;
    flex-basis: auto;
    .people-list {
      height: auto;
    }
  }
  .chat-left-aside {
    .people-list {
      ul {
        max-height: 100px;
      }
    }
  }
  // support ticket page
  .ecommerce-widget {
    .progress-showcase {
      margin-top: 15px;
    }
  }
  // forget-password page
  .reset-password-box {
    .theme-form {
      .form-group {
        .btn {
          margin-top: 13px !important;
        }
      }
    }
  }
  // gallery-with-description page
  .gallery-with-description {
    a {
      > div {
        margin-bottom: 15px;
      }
    }
  }
  // gallery hover page
  .gallery-img-mb-sm {
    margin-bottom: 15px;
  }
  // peity chart page
  .xm-mb-peity {
    margin-bottom: 8px;
  }
  // login page
  .authentication-main {
    padding: 30px 15px;
  }
}
/* ========= responsive scss for 480 screen ========= */
@media only screen and (max-width: 480px) {
  .main-image-container {
    #current-figure {
      > #current-image {
        height: 270px !important;
        object-fit: cover;
      }
    }
  }
  .loader-wrapper {
    .typewriter h1 {
      font-size: 17px;
    }
  }
  .knob-chart {
    min-height: 204px;
  }
  .knob-chart {
    .chart-clock-main {
      .clock-large {
        left: -25px;
        top: -42px;
        canvas,
        input {
          width: 245px !important;
          height: 245px !important;
        }
      }
      .clock-small {
        left: 37px;
        top: 23px;
        canvas,
        input {
          width: 120px !important;
          height: 120px !important;
        }
      }
      .clock-medium {
        left: 12px;
        top: -3px;
        canvas,
        input {
          width: 170px !important;
          height: 170px !important;
        }
      }
    }
  }
  .tron-knob {
    canvas,
    input {
      width: 220px !important;
      height: 220px !important;
    }
    input {
      margin-left: 15px !important;
    }
  }

  .modal-footer {
    .btn {
      padding: 6px;
    }
  }
  .crm-activity span {
    height: 40px;
    width: 40px;
    line-height: 1.6;
  }
  .contact-form {
    padding: 0 !important;
  }
  .mobile-clock-widget {
    height: 260px;
    .clock-details {
      margin-top: 60px;
      text-align: center;
    }
  }

  .weather-widget {
    .weather-widget-bottom {
      padding: 0 25px !important;
    }
    .col .box {
      padding: 10px 3px !important;
      svg {
        width: 25px;
      }
      h6 {
        font-size: 11px;
      }
      h5 {
        font-size: 14px !important;
      }
    }
  }
  .box-layout {
    &.page-wrapper {
      .set-col-6,
      .set-col-3,
      .set-col-7,
      .set-col-5 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  .inline-block-sm {
    display: inline-block;
  }
  .pagination-lg {
    .page-link {
      padding: 0.3rem 0.65rem;
      font-size: 16px;
    }
  }
  .nav-pills {
    li {
      width: 100%;
    }
  }
  .reset-password-box {
    width: auto;
  }
  .icon-hover-bottom {
    .icon-first {
      margin-right: 10px;
    }
    .icon-popup {
      .icon-class {
        display: none;
      }
    }
  }

  .error-wrapper {
    .maintenance-heading {
      margin-top: 0;
    }
  }
  .card {
    .card-header {
      > span {
        & + span {
          display: none;
        }
      }
    }
  }
  .custom-card {
    padding: 0;
    .card-footer {
      > div {
        h3 {
          font-size: 20px;
        }
      }
    }
    .card-social {
      li {
        padding: 5px 0;
      }
    }
    .profile-details {
      h6 {
        margin-bottom: 15px;
      }
      h4 {
        font-size: 20px;
      }
    }
    .card-profile {
      img {
        height: 93px;
      }
    }
  }

  .card {
    .card-header {
      .card-header-right {
        i {
          margin: 0 3px;
          font-size: 14px;
        }
      }
    }
  }

  //blog
  .blog-box.blog-shadow {
    .blog-details {
      padding: 10px;
      h4 {
        font-size: 14px;
      }
    }
  }
  .cal-date-widget {
    padding: 30px;
  }
  // email app
  .email-wrap {
    .email-right-aside {
      .email-content {
        .email-wrapper {
          .attachment {
            ul {
              li {
                img {
                  width: 83px;
                  height: 83px;
                }
              }
            }
          }
        }
      }
    }
  }
  //  tag-pills
  .badge {
    padding: 0.42em 0.7em;
  }
  // alert page
  .alert-dismissible {
    .close {
      top: -1px;
      span {
        font-size: 19px;
      }
    }
  }
  // bootstrap notify page
  .notify-alert {
    width: 90%;
  }
  // invoice template page
  .invoice {
    .text-md-right {
      margin-top: 10px;
    }
  }
}
@media only screen and (max-width: 420px) {
  .wizard-steps {
    .btn {
      padding: 6px 15px;
    }
  }
  .product-sidebar {
    &.open {
      top: -60px;
    }
  }
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        display: block;
        .product-img {
          margin: 0 auto;
          width: unset;
        }
        .product-details {
          text-align: center;
        }
      }
    }
  }
  .customizer-contain {
    width: 235px;
    .customizer-header {
      .icon-close {
        right: 20px;
      }
    }
    .customizer-body {
      padding: 20px;
      .layout-grid {
        &.customizer-mix {
          .color-layout {
            width: 60px;
            height: 56px;
          }
        }
      }
      .main-layout,
      .sidebar-type,
      .sidebar-setting {
        > li {
          width: 60px;
          height: 56px;
          > .body {
            ul {
              .body {
                .badge {
                  left: -13px;
                }
              }
            }
          }
        }
      }
      .color-layout {
        width: 27px;
        height: 27px;
        padding: 2px;
      }
      .tab-pane {
        > h6 {
          font-size: 16px;
        }
      }
    }
  }
  .customizer-color {
    margin-bottom: -13px;
  }
  .customizer-links {
    .nav-link {
      .settings {
        img {
          width: 56px;
        }
      }
    }
    &.open {
      right: 235px;
    }
  }
}

@media only screen and (max-width: 397px) {
  .clockpicker-align-top {
    top: 426px !important;
  }
}
/* ========= responsive scss for 360 screen ========= */
@media only screen and (max-width: 360px) {
  .welcome-popup {
    .modal-dialog {
      max-width: 269px;
      .modal-body {
        .p-30 {
          padding: 80px 10px 10px;
        }
      }
    }
  }
  .custom-card {
    .card-profile {
      margin-top: -26px;
    }
  }
  .note {
    min-width: 240px;
    min-height: 240px;
  }
  .add-post {
    form {
      .m-checkbox-inline {
        label {
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .datepicker-inline {
    .datepicker {
      max-width: 220px;
    }
  }
  .map-js-height {
    height: 250px;
  }
  .alert-theme {
    font-size: 12px;
    button {
      right: 30px !important;
    }
    i {
      display: none;
    }
    span {
      + span {
        + span {
          padding: 15px;
        }
      }
    }
  }
  .bar-chart-widget {
    .earning-details {
      i {
        right: -73px;
      }
    }
  }
  .widget-joins {
    .border-after-xs {
      &:after {
        width: 92%;
      }
    }
  }
  .page-wrapper {
    .page-main-header {
      .main-header-left {
        width: 25%;
      }
    }
  }
  // ngx chart
  .grid-pie-chart {
    &.flot-chart-container {
      height: 750px;
    }
  }
  // map
  agm-map {
    height: 250px !important;
  }
  .leaflet-custom {
    .map-height {
      height: 250px;
    }
  }
  // pagination css
  .pagination-sm {
    .page-link {
      padding: 2px 6px;
      font-size: 10px;
    }
  }
  .pagination-lg {
    .page-link {
      padding: 2px 7px;
    }
  }
  // chart widget page css
  .status-widget {
    svg {
      width: 18px;
      height: 18px;
    }
  }
  // user cards page css
  .custom-card {
    .card-footer {
      > div {
        h6 {
          font-size: 12px;
        }
      }
    }
  }
  // social app page css
  .tabs-scoial {
    &.nav-material {
      &.nav-tabs {
        .nav-item {
          .nav-link {
            padding: 10px 10px;
            font-size: 14px;
          }
        }
      }
    }
  }
  .user-profile {
    .hovercard {
      .user-image {
        .share-icons {
          right: 6px;
          top: -15px;
          li {
            .social-icon {
              width: 25px;
              height: 25px;
              i {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  .social-chat {
    .other-msg {
      margin-left: 15px;
    }
  }
  .social-header {
    h5 {
      span.pull-right {
        display: none;
      }
    }
  }
  // datepicker
  .custom-datepicker {
    .ngb-dp-navigation-select {
      .custom-select {
        width: 65px;
        &:first-child {
          margin-right: 6px;
        }
      }
    }
  }
  .custom-datepicker {
    ngb-datepicker,
    .dropdown-menu {
      width: 223px;
    }
  }
  .custom-card {
    .dashboard-card {
      padding-top: 0;
    }
    .card-profile {
      img {
        bottom: 25px;
      }
    }
  }
  .flot-chart-container {
    height: 230px;
  }
  .map-block {
    height: 250px;
  }
  .img-cropper {
    .docs-tooltip {
      font-size: 11px;
    }
    .docs-toggles {
      .btn-group {
        .btn {
          padding: 0.375rem 0.2rem;
        }
      }
    }
  }
  .blog-box {
    .blog-details {
      p {
        line-height: 1.5;
      }
    }
    .blog-details-main {
      .blog-social {
        li {
          padding: 0 10px;
        }
      }
    }
  }
  /* responsive material tabs*/
  .nav-material.nav-tabs {
    .nav-item {
      .nav-link {
        padding: 10px 0px;
      }
    }
  }
  .tabs-responsive-side {
    max-width: 100%;
  }
  .cal-date-widget {
    .datepicker {
      padding: 0;
      width: unset;
    }
  }
  //default general
  .browser-widget {
    .media {
      i {
        font-size: 52px;
      }
      .media-body {
        h4 {
          font-size: 20px;
        }
      }
    }
  }
  .bar-chart-widget {
    .bottom-content {
      .num {
        font-size: 20px;
      }
    }
  }
  .xs-width-100 {
    min-width: 100%;
  }
  // email app
  .email-wrap {
    .action-wrapper {
      .actions {
        li {
          margin-right: 13px;
        }
      }
    }
    .email-right-aside {
      .email-content {
        .email-wrapper {
          .attachment {
            ul {
              li {
                img {
                  width: 65px;
                  height: 65px;
                }
              }
            }
          }
        }
      }
      .email-top {
        .dropdown-menu {
          left: -58px;
        }
      }
    }
  }
  // according
  .default-according {
    .card {
      .card-header {
        i {
          font-size: 14px;
          top: 24px;
        }
      }
      .btn-link {
        display: block;
        width: 100%;
        padding-left: 17px;
      }
    }
  }
  //rating
  .star-ratings {
    .stars {
      .title {
        line-height: 1;
      }
    }
  }
  // tour page
  .user-profile {
    .hovercard {
      .social-media {
        a {
          padding: 0 12px;
        }
      }
    }
  }

  // touchspin page
  .bootstrap-touchspin {
    .touchspin-vertical-tab {
      .input-group {
        width: 93% !important;
      }
    }
  }
  // form-wizard-four page
  .step-container {
    height: 280px !important;
  }
  // chat page
  .chat-box {
    .chat-right-aside {
      flex: 0 0 96%;
      max-width: 96%;
      .chat {
        .chat-message {
          width: calc(100% - 20px);
        }
      }
    }
  }
  // error 400 page
  .error-wrapper {
    .error-heading {
      .headline {
        font-size: 100px;
      }
    }
  }
  // gallery page
  .lg-toolbar {
    .lg-icon {
      width: 40px;
    }
  }
}

/**=====================
    74. Responsive CSS Ends
==========================**/
